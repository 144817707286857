/*-----------------------------------------------------------*/
/* FONT
/*-----------------------------------------------------------*/
@mixin sf-font($size, $color, $type: 'regular', $family: false ,$italic: false){
  @if $type == 'ultralight' { font-weight: 100; }
  @else if $type == 'thin' { font-weight: 200; }
  @else if $type == 'light' { font-weight: 300; }
  @else if $type == 'normal' { font-weight: 400; }
  @else if $type == 'medium' { font-weight: 500; }
  @else if $type == 'semibold' { font-weight: 600; }
  @else if $type == 'bold' { font-weight: bold; }
  @else if $type == 'heavy' { font-weight: 800; }
  @else if $type == 'black' { font-weight: 900; }
  @else { font-weight: normal; }

  font-size: $size;
  color: $color;

  @if $family == 'text' { font-family: 'Inter';}
  @else { font-family: 'Inter'; }

  @if $italic { font-style: italic; }
  @else { font-style: normal; }
}

@mixin montserrat-font($size, $color, $type: 'regular', $family: false ,$italic: false){
  @if $type == 'thin' { font-weight: 100; }
  @else if $type == 'extralight' { font-weight: 200; }
  @else if $type == 'light' { font-weight: 300; }
  @else if $type == 'regular' { font-weight: 400; }
  @else if $type == 'medium' { font-weight: 500; }
  @else if $type == 'semibold' { font-weight: 600; }
  @else if $type == 'bold' { font-weight: bold; }
  @else if $type == 'extrabold' { font-weight: 800; }
  @else if $type == 'black' { font-weight: 900; }
  @else { font-weight: normal; }

  font-size: $size;
  color: $color;

  font-family: 'Inter';
  font-style: normal;
}

@mixin inter-font($size, $color, $type: 'regular', $family: false ,$italic: false){
  @if $type == 'thin' { font-weight: 100; }
  @else if $type == 'extralight' { font-weight: 200; }
  @else if $type == 'light' { font-weight: 300; }
  @else if $type == 'regular' { font-weight: 400; }
  @else if $type == 'medium' { font-weight: 500; }
  @else if $type == 'semibold' { font-weight: 600; }
  @else if $type == 'bold' { font-weight: bold; }
  @else if $type == 'extrabold' { font-weight: 800; }
  @else if $type == 'black' { font-weight: 900; }
  @else { font-weight: normal; }

  font-size: $size;
  color: $color;

  font-family: 'Inter';
  font-style: normal;
}

/*-----------------------------------------------------------*/
/* Button */
/*-----------------------------------------------------------*/
@mixin choice-button($color, $txtcolor, $width: auto, $height: 60px) {
  text-align: center;
  text-transform: capitalize;
  border-color: $color;
  color: $txtcolor;
  width: $width;
  height: $height;
}

@mixin action-button($color, $txtcolor, $width: auto, $height: 60px) {
  text-align: center;
  text-transform: capitalize;
  background-color: $color;
  color: $txtcolor;
  width: $width;
  height: $height;
}

@mixin cfa-button($color, $txtcolor, $width: auto, $height: 60px) {
  text-align: center;
  text-transform: uppercase;
  background-color: $color;
  color: $txtcolor;
  width: $width;
  height: $height;
}

@mixin cancel-button($width: auto, $height: 60px) {
  text-align: left;
  text-transform: capitalize;
  background-color: $background;
  color: $gray-dark;
  width: $width;
  height: $height;
}

@mixin general-button($txtcolor, $bgcolor) {
  background-color: $bgcolor;
  border-radius: 2px;
  padding: 1rem 1.5rem;
  cursor: pointer;
  user-select: none;
  font-size: 1.8rem;
  color: $txtcolor;
}

@mixin general-button-o($txtcolor, $bordercolor) {
  background-color: white;
  border-radius: 2px;
  border: 1px solid $bordercolor;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  user-select: none;
  font-size: 1.8rem;
  color: $txtcolor;
}

@mixin custom-button($txtcolor, $bgcolor, $padding, $fontsize) {
  background-color: $bgcolor;
  border-radius: 2px;
  padding: $padding;
  cursor: pointer;
  user-select: none;
  font-size: $fontsize;
  color: $txtcolor;
}

@mixin rounded-button($bgcolor, $txtcolor, $rounded, $width: auto, $height: 60px) {
  text-align: center;
  text-transform: capitalize;
  background-color: $bgcolor;
  border-radius: $rounded;
  color: $txtcolor;
  width: $width;
  height: $height;
}
/*-----------------------------------------------------------*/
/* Divider */
/*-----------------------------------------------------------*/
@mixin divider($side, $width, $color) {
  border-#{$side}-width: $width;
  border-style: solid;
  border-color: $color;
}

@mixin box-shadow($Shadow) {
  -webkit-box-shadow: $Shadow;
  -moz-box-shadow: $Shadow;
  box-shadow: $Shadow;
}

/*-----------------------------------------------------------*/
/* Responsie Media Query */
/*-----------------------------------------------------------*/
@mixin for-min-width-820 {
  @media (min-width: 820px) { @content; }
}
@mixin for-min-width-1200 {
  @media (min-width: 1200px) { @content; }
}
@mixin for-small-desktop {
  @media (min-width: 1280px) { @content; }
}
@mixin for-normal-desktop {
  @media (min-width: 1440px) { @content; }
}
@mixin for-extra-desktop {
  @media (min-width: 1600px) { @content; }
}

@mixin for-desktop {
  @media (min-width: 1025px) { @content; }
}

@mixin for-tablet-landscape {
  @media (min-width: 1024px) and (max-width: 1300px) {
    @content;
  }
}

@mixin for-tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin for-tablet-to-desktop {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin for-mobile-landscape {
  @media (min-width: 480px) and (max-width: 767px) {
    @content;
  }
}

@mixin for-mobile {
  @media (min-width: 320px) and (max-width: 480px) {
    @content;
  }
}

@mixin for-mobile-to-tablet {
  @media (min-width: 320px) and (max-width: 1024px) {
    @content;
  }
}

@mixin for-lg {
  @media (min-width: 992px) {
    @content;
  }
}
