@import "@/styles/variables.scss";

.template-sidebar {
  .SidebarNav {
    display: none;
    @include for-desktop {
      display: block;
      left: 0;
      padding-top: 80px;
    }
  }
}
