/*-----------------------------------------------------------*/
/* COLOR VARIABLES
/*-----------------------------------------------------------*/

  $primary: #2E2438;
  $secondary: #FACF92;
  $tertiary: #24B6F7;
  $quaternary: #FACF92;
  $placeholder: #3D3D3D;
  $complimentary: $secondary;
  $normal: #4d4f5c;
  $danger: #FB453C;
  $info: #0096D5;
  $warning: #FFBD00;
  $success: #00C33F;

  $shade1: #ffffff;
  $shade2: #D9D9D9;
  $shade3: #707070;
  $shade4: #3D3D3D;
  $shade5: #000000;
  $shade6: #F0F0F7;
  $shade7: #ECECEC;
  $shade8: #F7F7F7;
  $shade9: #FBFBFB;
  $shade10 : #0B0C0E;


  $foreground: $primary;
  $background: $shade1;

  $border1: #FCD08E;
  $border2: $shade3;
  $border3: $shade2;
  $border4: $shade7;

  $shadow   : 0px 10px 20px rgba(0, 0, 0, 0.1);

  $text_primary: $primary;
  $text_secondary: $shade4;
  $text_button: $shade1;
  $text_link: $tertiary;
  $text_disable: $shade3;
  $text_placeholder: #707070;

  $border_input: #D9D9D9;

  /* Green */
  $green            : #6FCF97;
  $green2           : #6FEEBB;
  $green3           : #D1F2EB;
  $green-dark       : #243935;
  $green-dark-blur34: rgba(36, 57, 53, 0.34);
  $green-dark-blur11: rgba(36, 57, 53, 0.11);
  $step-background-color: #6feebb;
  /* Orange */
  $orange           : #F5BCAA;
  $orange2          : #FF9100;
  $orange3          : #FACF92;
  /* Violet */
  $violet           : $secondary;
  /* Blue */
  $blue             : #6DBBFF;
  /* White */
  $white            : #FFFFFF;
  $white2           : #FCFCFC;
  /* Gray */
  $gray             : #D8D8D8;
  $midGray          : #707070;
  $gray-option      : $text_placeholder;
  $gray-light       : #E9E9E9;
  $gray-light2      : #D9D9D9;
  $gray-dark        : #959595;
  $gray-dark2       : #8B8B8B;
  $gray-dark3       : #808495;
  /* Black */
  $black            : #000000;
  $black2           : #3D3D3D;
  $black3           : rgba(112, 112, 112, .27);
  $black4           : #2E2438;
  /* Red */
  $red              : #FF5327;
  $red-cancel       : #f54949;
  /* pink */
  $pink             : #FB478F;
  /* Purple */
  $purple: #738AF8;

  /* shadow */
  $default-shadow   : 0px 10px 20px rgba(0, 0, 0, 0.1);
  $btn-shadow       : 0px 10px 20px rgba(0, 0, 0, 0.1);
  $y-axis-shadow    : 0px 10px 20px rgba(0, 0, 0, 0.1);
  $y-2axis-shadow   : 0px 10px 20px rgba(0, 0, 0, 0.1);
  $booking-shadow   : 0px 10px 20px rgba(0, 0, 0, 0.1);
  $y-card-axis-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

  $booking-colors: (
    booking-pending: #FFE26D,
    booking-approved: #6fcf97,
    booking-flagged: #fb478f,
    booking-offline: #D9D9D9,
    booking-holiday: #959595
  );

  $booking-colors-border: (
    booking-pending: rgb(204, 181, 87),
    booking-approved: #448f63,
    booking-flagged: #813051,
    booking-offline: #D9D9D9,
    booking-holiday: #3D3D3D
  );
