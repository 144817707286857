.et-silder {
  &.vue-slider-component {
    margin-top: 5rem;
  }
  &.vue-slider-component .vue-slider-dot .vue-slider-dot-handle,
  &.vue-slider-component.vue-slider-horizontal .vue-slider-process {
    background-color: $secondary;
    box-shadow: none;
  }
  &.vue-slider-component .vue-slider-tooltip {
    background-color: transparent;
    border: 0;
    @include sf-font($heading4, $text_secondary, 'semibold');
    &:before {
      border: 0 !important;
    }
  }
  &.vue-slider-component
    .vue-slider-tooltip-top
    .vue-merged-tooltip
    .vue-slider-tooltip:before,
  &.vue-slider-component
    .vue-slider-tooltip-wrap.vue-slider-tooltip-top
    .vue-slider-tooltip:before {
    border-top-color: inherit;
  }
  &.vue-slider-component {
    .vue-slider {
      height: 4px !important;
      .vue-slider-always:nth-child(2) {
        .vue-slider-tooltip-top {
          .vue-slider-tooltip:after {
            content: '+';
          }
        }
      }
    }
    .vue-slider-dot {
      .vue-slider-dot-handle {
        border: 2px solid $border1;
        box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32) !important;
        background-color: $background !important;
      }
    }
  }
}

.slider {
  display: flex;
  align-items: center;
  margin: 10rem 0;
  @include for-mobile {
    justify-content: center;
  }

  .slider {
    &__min,
    &__max {
      @include sf-font($text1, $text_secondary, 'regular');
    }
  }
  .et-silder {
    &.slider__item {
      flex: 0.7 0 0;
      margin: 0rem;
    }
  }
}