@import "@/styles/variables.scss";

.page-view {
  margin: 20px auto;
  min-height: 95vh;
  max-width: 1200px;
  padding:0 20px;
}

.Banner {
  width: 100%;

  & .cover-image {
    height: 600px;
  }
}

.Content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 65px;

  .Main {
    &.-singlecolumn {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding-top: 65px;
    }

    &.-twocolumn {
      flex: 0 0 67%;
    }
  }

  .Sidebar {
    flex: 0 0 33%;
  }

  .Footer {
    flex: 1 0 100%;
  }
}
