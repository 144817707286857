@import "@/styles/variables.scss";

.VenueGroupMenu {
  &.SidebarNav {
    background-color: $primary;
  }
  .SidebarNav {
    width: 305px;
  }
  .menu {
    background-color: $primary;
    a {
      cursor: default;
      font-size: 16px;
    }
  }
}
