@import "@/styles/variables.scss";

.Page-Loader.-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999; 

}

.Page-Loader_wrapper {
  color: $primary;
  display: flex;
  z-index: 100000;
  align-items: center;
  flex-direction: column;
}
