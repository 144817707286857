@import "@/styles/variables.scss";

.VenueMenu {
  .side-venue-group {
    // background-color: white !important;
    height: auto !important;
    min-height: auto !important;
  }
  &.SidebarNav {
    background-color: $primary;
    justify-content: flex-start;
  }
  .menu {
    flex: 0 0 auto;
    padding: 0;
    background-color: $primary;
    min-height: 100%;
    height: 100%;
    &-venue-group {
      &-back {
        @include sf-font(1.6rem, $gray, 'semibold');
        padding: 1rem 3.3rem 0rem 3.3rem;

        a {
          color: $gray;
          @include montserrat-font(14px, $white, 'regular');
          text-decoration: none;
          &:hover {
            @include montserrat-font(14px, $shade2, 'regular');
          }
        }
      }
    }
  }
  .menu-venue-group-back {
    margin-top: 44px;
  }
  .wrap-menue {
    height: calc(100vh - 80px);
    background-color: $primary;
  }
}
