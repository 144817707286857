@font-face {
  font-family: 'ET-Icon';
  src:  url('/public/icon/fonts/ET-Icon.eot?yah8e1');
  src:  url('/public/icon/fonts/ET-Icon.eot?yah8e1#iefix') format('embedded-opentype'),
    url('/public/icon/fonts/ET-Icon.ttf?yah8e1') format('truetype'),
    url('/public/icon/fonts/ET-Icon.woff?yah8e1') format('woff'),
    url('/public/icon/fonts/ET-Icon.svg?yah8e1#ET-Icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ET-Icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-mail-2:before {
  content: "\ea01";
}
.icon-task-asset-collection:before {
  content: "\e9fa";
}
.icon-task-asset-delivery:before {
  content: "\e9fb";
}
.icon-task-asset-setup:before {
  content: "\e9fc";
}
.icon-task-booking-approval:before {
  content: "\e9fd";
}
.icon-task-live-time:before {
  content: "\e9fe";
}
.icon-task-rate-and-review:before {
  content: "\e9ff";
}
.icon-task-staff-on-location:before {
  content: "\ea00";
}
.icon-sort-arrow:before {
  content: "\e9dc";
}
.icon-sort-arrow-down .path1:before {
  content: "\e9dd";
  color: rgb(0, 0, 0);
}
.icon-sort-arrow-down .path2:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(250, 207, 146);
}
.icon-sort-arrow-up .path1:before {
  content: "\e9df";
  color: rgb(250, 207, 146);
}
.icon-sort-arrow-up .path2:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-Chart:before {
  content: "\e9db";
}
.icon-map-pin-converted:before {
  content: "\e9da";
}
.icon-Settings:before {
  content: "\e9d9";
}
.icon-Gear-converted:before {
  content: "\e9d9";
}
.icon-Alarm:before {
  content: "\e9d0";
}
.icon-Clock1:before {
  content: "\e9d0";
}
.icon-Time:before {
  content: "\e9d0";
}
.icon-Timer:before {
  content: "\e9d0";
}
.icon-bullet-list-text:before {
  content: "\e9d1";
}
.icon-Calendar:before {
  content: "\e9d2";
}
.icon-Schedule:before {
  content: "\e9d2";
}
.icon-Clock:before {
  content: "\e9d2";
}
.icon-check-list-checkmark:before {
  content: "\e9d3";
}
.icon-cup-2:before {
  content: "\e9d4";
}
.icon-Favorite:before {
  content: "\e9d5";
}
.icon-Group1:before {
  content: "\e9d6";
}
.icon-User-1:before {
  content: "\e9d6";
}
.icon-Group:before {
  content: "\e9d7";
}
.icon-User:before {
  content: "\e9d7";
}
.icon-Money:before {
  content: "\e9d8";
}
.icon-Coins:before {
  content: "\e9d8";
}
.icon-Home-converted:before {
  content: "\e9cf";
}
.icon-campaign:before {
  content: "\e9f8";
}
.icon-Shop:before {
  content: "\e9f9";
}
.icon-calendar:before {
  content: "\e9e6";
}
.icon-Certificate:before {
  content: "\e9e7";
}
.icon-Medal:before {
  content: "\e9e7";
}
.icon-Experience-This-Logo-1:before {
  content: "\e9e8";
}
.icon-Group2:before {
  content: "\e9e9";
}
.icon-User1:before {
  content: "\e9e9";
}
.icon-Group3:before {
  content: "\e9ea";
}
.icon-User-11:before {
  content: "\e9ea";
}
.icon-Log-out1:before {
  content: "\e9eb";
}
.icon-message-review-star:before {
  content: "\e9ec";
}
.icon-bullet:before {
  content: "\e9ed";
}
.icon-Business:before {
  content: "\e9ee";
}
.icon-Chart1:before {
  content: "\e9ee";
}
.icon-Business1:before {
  content: "\e9ef";
}
.icon-Chart-1:before {
  content: "\e9ef";
}
.icon-Calendar1:before {
  content: "\e9f0";
}
.icon-Schedule1:before {
  content: "\e9f0";
}
.icon-Clock2:before {
  content: "\e9f0";
}
.icon-hand-package-delivery:before {
  content: "\e9f1";
}
.icon-menu-1:before {
  content: "\e9f2";
}
.icon-Money1:before {
  content: "\e9f3";
}
.icon-Coins1:before {
  content: "\e9f3";
}
.icon-restaurant1:before {
  content: "\e9f4";
}
.icon-target-goal:before {
  content: "\e9f5";
}
.icon-Vector:before {
  content: "\e9f6";
}
.icon-wine-glass-bottle:before {
  content: "\e9f7";
}
.icon-floppy-o:before {
  content: "\f0c7";
}
.icon-save:before {
  content: "\f0c7";
}
.icon-keyboard-o:before {
  content: "\f11c";
}
.icon-clock-o:before {
  content: "\f017";
}
.icon-chain:before {
  content: "\f0c1";
}
.icon-link1:before {
  content: "\f0c1";
}
.icon-history:before {
  content: "\f1da";
}
.icon-trash1:before {
  content: "\f1f8";
}
.icon-check-circle1:before {
  content: "\e9e5";
}
.icon-arrow-down-circle:before {
  content: "\e9e1";
}
.icon-arrow-left-circle:before {
  content: "\e9e2";
}
.icon-arrow-right-circle:before {
  content: "\e9e3";
}
.icon-arrow-up-circle:before {
  content: "\e9e4";
}
.icon-highlight_remove:before {
  content: "\e9cb";
}
.icon-swap_vert:before {
  content: "\e9ce";
}
.icon-x-circle:before {
  content: "\e9cc";
}
.icon-visibility_off:before {
  content: "\e9c9";
}
.icon-remove_red_eye:before {
  content: "\e9ca";
}
.icon-visibility:before {
  content: "\e9ca";
}
.icon-Arrange:before {
  content: "\e9cd";
}
.icon-share-01:before {
  content: "\e9c7";
}
.icon-share-alt:before {
  content: "\e9c8";
}
.icon-bookmark-solid:before {
  content: "\e9c6";
}
.icon-avatar:before {
  content: "\e9c5";
}
.icon-party:before {
  content: "\e900";
}
.icon-arrow-forward:before {
  content: "\e901";
}
.icon-temporary-decor:before {
  content: "\e902";
}
.icon-digital-screens:before {
  content: "\e903";
}
.icon-gas-supply:before {
  content: "\e904";
}
.icon-ground-floor-only:before {
  content: "\e905";
}
.icon-mobile-payment-system:before {
  content: "\e906";
}
.icon-outdoor-space:before {
  content: "\e907";
}
.icon-parking-on-location:before {
  content: "\e908";
}
.icon-playing-music:before {
  content: "\e909";
}
.icon-plugs-available:before {
  content: "\e90a";
}
.icon-preparing-cocktails:before {
  content: "\e90b";
}
.icon-price-promotions-on-till:before {
  content: "\e90c";
}
.icon-room-for-asset:before {
  content: "\e90d";
}
.icon-serving-food:before {
  content: "\e90e";
}
.icon-sky-other:before {
  content: "\e90f";
}
.icon-sound-system:before {
  content: "\e910";
}
.icon-spare-line-for-draught:before {
  content: "\e911";
}
.icon-track-booking:before {
  content: "\e912";
}
.icon-tv-screens:before {
  content: "\e913";
}
.icon-booking-event:before {
  content: "\e914";
}
.icon-Log-out:before {
  content: "\e915";
}
.icon-profile:before {
  content: "\e916";
}
.icon-asset-collection:before {
  content: "\e917";
}
.icon-asset-delivery:before {
  content: "\e918";
}
.icon-asset-set-up:before {
  content: "\e919";
}
.icon-booking-approval:before {
  content: "\e91a";
}
.icon-live-time:before {
  content: "\e91b";
}
.icon-rate-and-review:before {
  content: "\e91c";
}
.icon-sponsorship-requested:before {
  content: "\e91d";
}
.icon-staff-on-location:before {
  content: "\e91e";
}
.icon-stock-order:before {
  content: "\e91f";
}
.icon-timeline-check-circle:before {
  content: "\e920";
}
.icon-timeline-circle:before {
  content: "\e921";
}
.icon-timeline-detail:before {
  content: "\e922";
}
.icon-timeline-exclamation-mark:before {
  content: "\e923";
}
.icon-bar:before {
  content: "\e924";
}
.icon-casual-dining:before {
  content: "\e925";
}
.icon-caterer:before {
  content: "\e926";
}
.icon-club:before {
  content: "\e927";
}
.icon-entertainment:before {
  content: "\e928";
}
.icon-event-planner:before {
  content: "\e929";
}
.icon-event:before {
  content: "\e92a";
}
.icon-hotel:before {
  content: "\e92b";
}
.icon-pub:before {
  content: "\e92c";
}
.icon-restaurant:before {
  content: "\e92d";
}
.icon-sport-social:before {
  content: "\e92e";
}
.icon-student-union:before {
  content: "\e92f";
}
.icon-checked:before {
  content: "\e930";
}
.icon-star-filled:before {
  content: "\e931";
}
.icon-bespoke-install:before {
  content: "\e932";
}
.icon-festival-asset:before {
  content: "\e933";
}
.icon-food:before {
  content: "\e934";
}
.icon-announcement:before {
  content: "\e935";
}
.icon-archive:before {
  content: "\e936";
}
.icon-arrow-down:before {
  content: "\e937";
}
.icon-arrow-left:before {
  content: "\e938";
}
.icon-arrow-right:before {
  content: "\e939";
}
.icon-arrow-up:before {
  content: "\e93a";
}
.icon-at-symbol:before {
  content: "\e93b";
}
.icon-book:before {
  content: "\e93c";
}
.icon-bookmark:before {
  content: "\e93d";
}
.icon-briefcase:before {
  content: "\e93e";
}
.icon-browser:before {
  content: "\e93f";
}
.icon-building:before {
  content: "\e940";
}
.icon-calander:before {
  content: "\e941";
}
.icon-call:before {
  content: "\e942";
}
.icon-call-incoming:before {
  content: "\e943";
}
.icon-call-outgoing:before {
  content: "\e944";
}
.icon-camera:before {
  content: "\e945";
}
.icon-cart:before {
  content: "\e946";
}
.icon-chat:before {
  content: "\e947";
}
.icon-check-circle:before {
  content: "\e948";
}
.icon-cheveron-down:before {
  content: "\e949";
}
.icon-cheveron-left:before {
  content: "\e94a";
}
.icon-cheveron-right:before {
  content: "\e94b";
}
.icon-cheveron-up:before {
  content: "\e94c";
}
.icon-clip:before {
  content: "\e94d";
}
.icon-clipboard:before {
  content: "\e94e";
}
.icon-clock:before {
  content: "\e94f";
}
.icon-code:before {
  content: "\e950";
}
.icon-cog:before {
  content: "\e951";
}
.icon-comment:before {
  content: "\e952";
}
.icon-compass:before {
  content: "\e953";
}
.icon-currency-dollar:before {
  content: "\e954";
}
.icon-dashboard:before {
  content: "\e955";
}
.icon-desktop:before {
  content: "\e956";
}
.icon-discount:before {
  content: "\e957";
}
.icon-download:before {
  content: "\e958";
}
.icon-duplicate:before {
  content: "\e959";
}
.icon-edit:before {
  content: "\e95a";
}
.icon-emotion-happy:before {
  content: "\e95b";
}
.icon-emotion-sad:before {
  content: "\e95c";
}
.icon-exclamation:before {
  content: "\e95d";
}
.icon-external-link:before {
  content: "\e95e";
}
.icon-file:before {
  content: "\e95f";
}
.icon-file-blank:before {
  content: "\e960";
}
.icon-file-minus:before {
  content: "\e961";
}
.icon-file-plus:before {
  content: "\e962";
}
.icon-film:before {
  content: "\e963";
}
.icon-filter:before {
  content: "\e964";
}
.icon-flag:before {
  content: "\e965";
}
.icon-folder:before {
  content: "\e966";
}
.icon-folder-minus:before {
  content: "\e967";
}
.icon-folder-plus:before {
  content: "\e968";
}
.icon-globe:before {
  content: "\e969";
}
.icon-graph-bar:before {
  content: "\e96a";
}
.icon-grid:before {
  content: "\e96b";
}
.icon-group:before {
  content: "\e96c";
}
.icon-hashtag:before {
  content: "\e96d";
}
.icon-heart:before {
  content: "\e96e";
}
.icon-help:before {
  content: "\e96f";
}
.icon-home:before {
  content: "\e970";
}
.icon-hourglass:before {
  content: "\e971";
}
.icon-image:before {
  content: "\e972";
}
.icon-inbox:before {
  content: "\e973";
}
.icon-info:before {
  content: "\e974";
}
.icon-information:before {
  content: "\e975";
}
.icon-key:before {
  content: "\e976";
}
.icon-link:before {
  content: "\e977";
}
.icon-location:before {
  content: "\e978";
}
.icon-lock-closed:before {
  content: "\e979";
}
.icon-lock-open:before {
  content: "\e97a";
}
.icon-mail:before {
  content: "\e97b";
}
.icon-map:before {
  content: "\e97c";
}
.icon-menu:before {
  content: "\e97d";
}
.icon-microphone:before {
  content: "\e97e";
}
.icon-minus:before {
  content: "\e97f";
}
.icon-minus-circle:before {
  content: "\e980";
}
.icon-minus-square:before {
  content: "\e981";
}
.icon-mobile:before {
  content: "\e982";
}
.icon-moon:before {
  content: "\e983";
}
.icon-more-horiz:before {
  content: "\e984";
}
.icon-music:before {
  content: "\e985";
}
.icon-news:before {
  content: "\e986";
}
.icon-notification:before {
  content: "\e987";
}
.icon-plus:before {
  content: "\e988";
}
.icon-plus-circle:before {
  content: "\e989";
}
.icon-plus-square:before {
  content: "\e98a";
}
.icon-print:before {
  content: "\e98b";
}
.icon-puzzle:before {
  content: "\e98c";
}
.icon-refresh:before {
  content: "\e98d";
}
.icon-repeat:before {
  content: "\e98e";
}
.icon-rocket:before {
  content: "\e98f";
}
.icon-search:before {
  content: "\e990";
}
.icon-server:before {
  content: "\e991";
}
.icon-speaker:before {
  content: "\e992";
}
.icon-create-campaign:before {
  content: "\e993";
}
.icon-square-check:before {
  content: "\e994";
}
.icon-star:before {
  content: "\e995";
}
.icon-store:before {
  content: "\e996";
}
.icon-tablet:before {
  content: "\e997";
}
.icon-tag:before {
  content: "\e998";
}
.icon-thumb-down:before {
  content: "\e999";
}
.icon-thumb-up:before {
  content: "\e99a";
}
.icon-trash:before {
  content: "\e99b";
}
.icon-trending-down:before {
  content: "\e99c";
}
.icon-trending-up:before {
  content: "\e99d";
}
.icon-trophy:before {
  content: "\e99e";
}
.icon-upload:before {
  content: "\e99f";
}
.icon-user:before {
  content: "\e9a0";
}
.icon-user-check:before {
  content: "\e9a1";
}
.icon-user-minus:before {
  content: "\e9a2";
}
.icon-user-plus:before {
  content: "\e9a3";
}
.icon-video:before {
  content: "\e9a4";
}
.icon-view:before {
  content: "\e9a5";
}
.icon-x:before {
  content: "\e9a6";
}
.icon-x-circle1:before {
  content: "\e9a7";
}
.icon-x-square:before {
  content: "\e9a8";
}
.icon-zoom-in:before {
  content: "\e9a9";
}
.icon-zoom-out:before {
  content: "\e9aa";
}
.icon-large-vehicle:before {
  content: "\e9ab";
}
.icon-playing-video:before {
  content: "\e9ac";
}
.icon-pound-round:before {
  content: "\e9ad";
}
.icon-timetable:before {
  content: "\e9ae";
}
.icon-handshake:before {
  content: "\e9af";
}
.icon-megaphone:before {
  content: "\e9b0";
}
.icon-smile:before {
  content: "\e9b1";
}
.icon-successful:before {
  content: "\e9b2";
}
.icon-unsuccessful:before {
  content: "\e9b3";
}
.icon-staff:before {
  content: "\e9b4";
}
.icon-masterclass:before {
  content: "\e9b5";
}
.icon-team:before {
  content: "\e9b6";
}
.icon-venue:before {
  content: "\e9b7";
}
.icon-events:before {
  content: "\e9b8";
}
.icon-marketplace:before {
  content: "\e9b9";
}
.icon-duration:before {
  content: "\e9ba";
}
.icon-signup:before {
  content: "\e9bb";
}
.icon-master-class:before {
  content: "\e9bc";
}
.icon-water-supply:before {
  content: "\e9bd";
}
.icon-spare-tap-for-draught:before {
  content: "\e9be";
}
.icon-mobile-bar:before {
  content: "\e9bf";
}
.icon-live-music:before {
  content: "\e9c0";
}
.icon-pos:before {
  content: "\e9c1";
}
.icon-trailers:before {
  content: "\e9c2";
}
.icon-training:before {
  content: "\e9c3";
}
.icon-basket:before {
  content: "\e9c4";
}
.icon-spinner:before {
  content: "\ea20";
}