@font-face {
  font-family: 'icon-venueIdentifier';
  src:  url('/public/icon/et-venueIdentifier/fonts/et-venueIdentifier.eot?fyu9p0');
  src:  url('/public/icon/et-venueIdentifier/fonts/et-venueIdentifier.eot?fyu9p0#iefix') format('embedded-opentype'),
    url('/public/icon/et-venueIdentifier/fonts/et-venueIdentifier.ttf?fyu9p0') format('truetype'),
    url('/public/icon/et-venueIdentifier/fonts/et-venueIdentifier.woff?fyu9p0') format('woff'),
    url('/public/icon/et-venueIdentifier/fonts/et-venueIdentifier.svg?fyu9p0#et-requirements') format('svg');
  font-weight: normal;
  font-weight: normal;
  font-style: normal;
}

[class^="venueidentifier"], [class*=" venueidentifier"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon-venueIdentifier' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.venueidentifier-student-union:before {
  content: "\e900";
}
.venueidentifier-event-planner:before {
  content: "\e901";
}
.venueidentifier-caterer:before {
  content: "\e902";
}
.venueidentifier-bar:before {
  content: "\e903";
}
.venueidentifier-casual-dining:before {
  content: "\e904";
}
.venueidentifier-club:before {
  content: "\e905";
}
.venueidentifier-entertainment:before {
  content: "\e906";
}
.venueidentifier-event:before {
  content: "\e907";
}
.venueidentifier-hotel:before {
  content: "\e908";
}
.venueidentifier-pub:before {
  content: "\e909";
}
.venueidentifier-restaurant:before {
  content: "\e90a";
}
.venueidentifier-sport-social:before {
  content: "\e90b";
}