@import "@/styles/variables.scss";

.GuestMenu {
  &.SidebarNav {
    padding-top: 76px;
    background-color: $primary;
  }
  .menu {
    flex: 0 0 auto;
    padding: 0;
    &-venue-group {
      &-back {
        @include sf-font(1.6rem, $gray, 'semibold');
        padding: 1rem 3.3rem 0rem 3.3rem;

        a {
          color: $gray;
          @include montserrat-font($heading5, $shade2, 'regular');
          text-decoration: none;
          &:hover {
            @include montserrat-font($heading5, $primary, 'regular');
          }
        }
      }
    }
  }
  .menu-venue-group-back {
    margin-top: 44px;
  }
}
