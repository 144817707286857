/*----------------------*/
/* BREAKPOINT VARIABLES */
/*----------------------*/
// Smart phone
$mob: 576px;
// Tablet
$tab: 768px;
$ipad: 1025px;
// PC
$pc: 1200px;

/*-------------------*/
/* MEDIA QUERY MIXIN */
/*-------------------*/
// Small devices
@mixin sm {
  @media (min-width: #{$mob}) {
      @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$tab}) {
      @content;
  }
}

// Tablet, ipad pro devices
@mixin tab {
  @media (min-width: #{$ipad}) {
      @content;
  }
}

// Larger devices
@mixin lg {
  @media (min-width: #{$pc}) {
      @content;
  }
}

// Mobile view
@mixin mobile {
  @media (max-width: 53.125em)  { @content ; }
}

// Tablet view
@mixin tablet {
  @media (max-width: 74.6875em) { @content ; }
}

//Custom breakpint calendar
@mixin tab-1 {
  @media (max-width: #{1880px}) {
      @content;
  }
}

@mixin tab-2 {
  @media (max-width: #{1795px}) {
      @content;
  }
}

@mixin tab-3 {
  @media (max-width: #{1647px}) {
      @content;
  }
}

@mixin tab-4 {
  @media (max-width: #{1549px}) {
      @content;
  }
}

 @mixin tab-5 {
  @media (max-width: #{1451px}) {
      @content;
  }
}
@mixin tab-6 {
  @media (max-width: #{1285px}) {
      @content;
  }
}

@mixin tab-7 {
  @media (max-width: #{1015px}) {
      @content;
  }
}

@mixin only-1024 {
  @media (min-width: 1023px) and (max-width: 1024px) {
    @content;
  }
}

@mixin  only-i-pad-pro-landscape {
  @media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: landscape) {
    @content;
  }
}
