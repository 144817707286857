@import "@/styles/variables.scss";

// Fix for space footer when page loading.
.main {
  min-height: calc(100vh - 290px);
}

.template-no-sidebar {
  margin-top: 12rem;
}
