.data-table {
  .header {
    display: flex;
    align-items: center;
    padding: 2.5rem 0;
    @include for-mobile {
      display: block;
    }
    .page-title {
      @include sf-font(2em, $black2, 'semibold');
      @include for-mobile {
        margin-bottom: 1rem;
      }
      flex: 1 0 0;
    }
    .pagination {
      flex: 1 0 0;
      padding: 20px;
      margin: 0;
      li {
        display: inline-block;
        padding: 10px;
      }
    }
    .search-box {
      margin-right: 22px;
      @include for-mobile {
        margin-right: 0;
        input{
          width: 100%;
          margin: 10px 0;
          margin-top: 0;
        }
        .icon-search{
          top: 25px;
          @include for-mobile {
            top: 15px;
          }
        }
      }
      input {
        color: $gray-dark;
        border: 1px solid $gray;
        outline: none;
        &::placeholder {
          @include sf-font(1.3rem, $gray-dark, 'regular');
        }
        &:focus {
          border: 1px solid $gray;
        }
      }
    }
    .el-select {
      @include for-mobile {
        width: 100%;
      }
    }
    .add-button {
      @include action-button(#a3a0fb, #ffffff, 160px, 40px);
      margin-right: 22px;
      @include for-mobile {
        width: 100%;
        margin-right: 0;
      }
    }
    .return-button {
      @include action-button(#ffffff, #a3a0fb, 160px, 40px);
      border: 1px solid #a3a0fb;
      @include for-mobile {
        width: 100%;
      }
    }
    button {
      outline: none;
    }
  }
  .el-tabs {
    &__header {
      width: 100% !important;
      padding: 16px 0;
      margin: 0;
      &-wrapper {
        background-color: #a3a6b4;
      }
    }
    &__nav-wrap {
      &::after {
        background-color: transparent;
      }
      td {
        border-bottom: 1;
      }
      &__active-bar {
        visibility: hidden;
      }
    }
    &__active-bar {
      visibility: hidden;
    }
    &__item {
      color: $gray-light2;
      p {
        outline: none;
        margin-bottom: 0;
      }
      .badge {
        background-color: $gray-light2;
        position: relative;
        color: #fff;
        border-radius: 8px;
        font-size: 1.2rem;
        padding: 0.2rem 0.75rem;
      }
      &.is-active {
        color: #4D4F5C;
        .badge {
          background-color: $secondary;
        }
      }
      &:hover {
        color: #4D4F5C;
      }
    }
  }
}

.data-table-style {
  .el-tabs {
    &__header {
      width: 100% !important;
      padding: 16px 10px;
      margin: 0;
      &-wrapper {
        background-color: #a3a6b4;
      }
    }
    &__nav-wrap {
      &::after {
        background-color: transparent;
      }
      td {
        border-bottom: 1;
      }
      &__active-bar {
        visibility: hidden;
      }
    }
    &__active-bar {
      visibility: hidden;
    }
    &__item {
      @include sf-font(14px, $shade2, 'semibold');
      p {
        outline: none;
        margin-bottom: 0;
      }
      .badge {
        background-color: $gray-light2;
        position: relative;
        color: #fff;
        border-radius: 8px;
        font-size: 1.2rem;
        padding: 0.2rem 0.75rem;
      }
      &.is-active {
        color: $black2;
        .badge {
          background-color: $secondary;
        }
      }
      &:hover {
        color: #4D4F5C;
      }
    }
  }
  .et-table {
    .el-table {
      &__header {
        &-wrapper {
          tr th {
            background-color: transparent;
          }
        }
      }
      tr {
        opacity: .5;
      }
      .on-selected {
        background-color: transparent;
        opacity: 1;
        td {
          border-top: 2px solid $secondary;
          border-bottom: 2px solid $secondary;
          &:first-child {
            border-left: 2px solid $secondary;
            border-radius: 2px 0 0 2px;
          }
          &:last-child {
            border-right: 2px solid $secondary;
            border-radius: 0 2px 2px 0;
          }
        }
      }
    }
    box-shadow: none !important;
  }
}

/** custom style expTable **/
.et-table {
  .el-table {
    &__header {
      &-wrapper {
        tr th {
          background-color: #f5f6fa;
          border-bottom: 0 !important;
          .cell {
            @include sf-font(13px, #a3a6b4, 'bold');
            text-transform: uppercase;
            word-break: normal;
            word-break: break-word;
            @include for-tablet {
              @include sf-font(1.2rem, #a3a6b4, 'bold');
            }

            @include for-mobile-landscape {
              @include sf-font(1.2rem, #a3a6b4, 'bold');
            }

            @include for-mobile {
              @include sf-font(1.2rem, #a3a6b4, 'bold');
            }
            p {
              margin-bottom: 0;
            }
            i {
              font-weight: 900;
              padding-left: 0.5rem;
            }
            .item {
              display: flex;
              align-self: center;
            }
          }
        }
      }
    }
    &__body {
      &-wrapper {
        tr {
          height: 70px;
          td {
            border-bottom: 1px solid #f1f1f3;
            .cell {
              word-break: break-word;
              min-height: auto;
              word-break: normal;
              @include sf-font(15px, #3D3D3D, 'regular');
              .action {
                text-align: center;
              }
              @include for-tablet {
                .action {
                visibility: visible;
              }
            }
              @include for-mobile-landscape {
                .action {
                  visibility: visible;
                }
              }
              @include for-mobile {
                .action {
                  visibility: visible;
                }
              }
              p {
                margin-bottom: 0;
              }
              .item {
                display: flex;
                img {
                  min-width: 30px;
                  min-height: 30px;
                  width: 30px;
                  height: 30px;
                  border-radius: 4px;
                  align-self: center;
                  margin: 0 10px;
                }
                p {
                  @include sf-font(1.3rem, #4d4f5c, 'semibold');
                  align-self: center;
                }
              }
            }
          }
        }
      }
    }
    .ascending {
      .sort-caret.ascending {
        border-bottom-color: #a3a6b4;
      }
    }
    .descending {
      .sort-caret.descending {
        border-top-color: #a3a6b4;
      }
    }
    .on-selected {
      background-color: #ebeef5;
    }
  }

  button {
    outline: none;
  }

  .budget {
    &-data {
      display: flex;
      align-items: center;
      height: 69px;
    }
    &-row {
      td {
        padding: 0;
      }
    }
  }
}

// Override some el-table without add !important
// to avoid specificity problem.
.el-table {
  .cell {
    word-break: normal;

    p {
      margin-bottom: 0;
    }
  }
  span.caret-wrapper {
    display: none;
    position: relative;
  }

  th:not(:first-child),
  th:last-child {
    & > .cell {
      padding: 0 2px;
    }
  }
}
