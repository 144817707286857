/* ------------------------------------ */
/* DEFAULT BEHAVIOUR */
/* ------------------------------------ */
/* Text
   ---- */
html {
  font-size: 50% !important;
  @media (min-width: 768px) {
    font-size: 62.5% !important;
  }
}

body {
  font-size: 1.4rem;
	font-family: 'Inter';
	font-weight: normal;
	font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

/* Link
   ---- */
.router-link, .router-link-active {
  text-decoration: none;
}

/* Button
   ------ */
button {
  border: none;
  cursor: pointer;
}

/* Button
   ------ */
input {
  border: none;
}

   /* List
   ---- */
li { list-style: none; }
