@import "@/styles/variables.scss";

.SidebarNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
     width: 240px !important;
  &.is-open {
    display: block !important;
    position: fixed;
    top: 0px;
    bottom: initial !important;
    height: auto !important;
    z-index: 100;
    background-color: unset;
    box-shadow: 0 5px 15px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    @include for-mobile {
      top: 58px;
    }
    @include for-mobile-landscape {
      top: 80px;
    }
    @include for-tablet {
      top: 70px;
    }
  }

  /deep/ .menu {
    padding: 3rem 0;
    @include for-mobile-to-tablet {
      overflow-y: scroll;
    }
    li {
      white-space: nowrap;
    }
  }
}
.ipadLandscape {
  top: 10px;
}
