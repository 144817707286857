@import "@/styles/variables.scss";

.AgencyMenu {
  .horizontal {
    padding: 12px 10px;
    /* padding-left: 10px; */
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* @include sf-font(1em, $shade3); */
    @include montserrat-font(12px, $shade3);

    .section {
      width: auto;
    }
    .hr {
      margin-left: 10px;
      display: inline-block;
      width: auto;
      flex-grow: 1;
      border: 1px solid $shade3;
    }
  }
  &.SidebarNav {
    background-color: $primary;
  }
}
.brand-logo {
  padding: 50px 36px 0;

  .logo {
    width: 40px;
    height: 40px;
    border-radius: 11px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.16);
  }

  .brand-select {
    @include sf-font(1.4rem, #3d3d3d);
    background-color: #ffffff;
    border: none;
    margin-left: 0.75rem;
    padding: 0.75rem;
  }
}

.menu {
  flex: 1 0 auto;

  .counter {
    @include sf-font(1.4rem, #ffffff);
    background-color: #a3a0fb;
    border-radius: 9px;
    padding: 2px 6px;
    margin-left: 1.5rem;
  }
}
