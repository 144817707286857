@import "@/styles/variables.scss";

@media (min-width: 1025px) and (max-width: 1439px) {
  .mobile-style a {
    font-size: clamp(10px, 0.5vw, 12px) !important;
  }
}
.BrandMenu {
  overflow-y: hidden !important;
  .horizontal {
    padding: 12px 10px;
    /* padding-left: 10px; */
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* @include sf-font(1em, $shade3); */
    @include montserrat-font(12px, $shade3);

    .section {
      width: auto;
    }
    .hr {
      margin-left: 10px;
      display: inline-block;
      width: auto;
      flex-grow: 1;
      border: 1px solid $shade3;
    }
  }
  &.SidebarNav {
    background-color: $primary;
  }
  .brand-logo {
    padding: 50px 36px 0;

    .logo {
      width: 40px;
      height: 40px;
      border-radius: 11px;
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.16);
    }

    .brand-select {
      @include sf-font(1.4rem, #3d3d3d);
      background-color: #ffffff;
      border: none;
      margin-left: 0.75rem;
      padding: 0.75rem;
    }
  }

  .menu {
    .counter {
      @include sf-font(1.4rem, #ffffff);
      background-color: rgb(49, 48, 73);
      border-radius: 9px;
      padding: 2px 6px;
      margin-left: 1.5rem;
    }
  }
  .sidebar-brand {
    // border-top: 1px solid white;
  }

  .sub-menu-campaign-list {
    padding: 1.5rem 0 0 1.5rem;
    color: $quaternary;
  }

  .sub-menu-campaign {
    &::before {
      margin-right: 0.5rem;
    }
  }
  .buttom-space {
    padding-bottom: 30px;
  }
}
