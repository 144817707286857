// Override element-plus theme variables
// @forward 'element-plus/theme-chalk/src/common/var.scss' with (
//   $colors: (
//     'primary': (
//       'base': #2E2438
//     ),
//   )
// );


.el-select__wrapper {
  height: 44px;
  border: 1px solid $border_input;
  .el-input__inner {
    @include sf-font(16px, $text_placeholder, "normal");
    &::placeholder {
      color: $text_placeholder;
    }
  }
}
.el-input__wrapper {
  border: 1px solid $border_input;
  height: 44px;
}
.el-divider--horizontal {
  &.no-margin {
    margin: 0;
  }
}
