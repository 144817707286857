@import "@/styles/variables.scss";

.et-footer {
  @include sf-font(10pt, $white);
  background-color: $black;
  padding: 25px 0;
  @include for-mobile {
    padding: 20px 0;
  }

  .link-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .menu-label {
      a {
        @include sf-font(10pt, $white);
        text-decoration: none;
      }
      @include for-mobile {
        display: flex;
        align-items: baseline;
        justify-content: center;
        a {
          padding-left: 1rem;
          display: block;
          margin-top: 1rem;
          margin-right: 7px;
          @include sf-font(8pt, $white);
        }
      }
      @include for-mobile-landscape {
        text-align: center;
      }
    }

    .secondary-font {
      color: $orange2;
      @include for-mobile {
        //display: none;
      }
    }

    .copyright-label {
      @include sf-font(8pt, $white);
      @include for-mobile {
        margin-top: 1rem;
        text-align: center;
        @include sf-font(5pt, $white);
      }
      @include for-mobile-landscape {
        text-align: center;
      }
    }
  }

  .logo-footer {
    .logo-group {
      display: flex;
      justify-content: flex-end;
      margin-right: 98px;
      @include for-mobile {
        margin-top: 30px;
        margin-right: unset;
        justify-content: flex-start;
      }
    }
    .logo-experience {
      width: 100%;
      background-image: url('/public/icon/svg/et-logo-white.svg');
      background-position: center right 100px;
      background-size: 50%;
      min-height: 50px;
      margin: 15px 0;
      @include for-tablet {
        background-size: 70%;
      }
      @include for-mobile {
        padding-top: 50px;
        background-size: 70%;
      }
      @include for-mobile-landscape {
        padding-top: 100px;
      }
    }
    .link-drinkaware {
      display: flex;
      justify-content: flex-end;
      margin-right: 98px;
      align-items: center;
      color: inherit !important;
      @include for-mobile-to-tablet {
        flex-direction: column;
      }
      .link {
        text-decoration: none;
        color: inherit !important;
        font-size: inherit !important;
        font-weight: inherit !important;
        @include for-mobile-to-tablet {
          align-self: flex-start;
        }
      }
    }
  }
}
