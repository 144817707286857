$text1: 16px;
$text2: 28px;
$text3: 14px;

$heading1: 40px;
$heading2: 24px;
$heading3: 22px;
$heading4: 15px;
$heading5: 12px;

$lineHeights1: 1;
$lineHeights2: 1.125;
$lineHeights3: 1.25;
$lineHeights4: 1.5;

$letterSpacings_normal: normal;
$letterSpacings_caps: 0.25em;
  