.Venue_Experience_Profile {
  .text {
    .Experience_name {
      @include montserrat-font(4rem, $black2, 'regular');
      margin-bottom: 10px;
      margin-top: 30px;

      @include for-mobile {
        height: auto;
        margin-bottom: 2rem;
      }
    }
    .text {
      @include sf-font(2.2rem, $black2, 'medium');
      line-height: 33px;
    }
  }

  .Experience_price_status {
    .Price-Tag {
      @include sf-font(2rem, $black2, 'normal');
      &.Sponsor-Full {
        text-decoration: line-through;
      }
      &.Sponsor-None {
        text-decoration: none;
      }
    }
    .Experience_sponsor-status {
      @include sf-font(1.7rem, white, 'regular');
      background-color: $secondary;
      border-radius: 27px;
      margin-left: 0;
      padding: 8px 20px;
    }
  }

  .Experience_Images .carousel__viewport {
    // background: black;
    // height: 311px;
    border-radius: 10px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }

  

  .Experience_Images {
    width: 100%;
    max-height: 285px;
    // margin: 2rem auto 0 auto;
    border-radius: 10px;


  }

//   .Experience_Images .carousel__prev {
//     margin: 0;
//     margin-left: -40px !important;
//     background: unset;
//   }
  
  
//  .Experience_Images .carousel__next {
//     margin: 0;
//     margin-right: -40px !important;
//     background: unset;
//   }

  .Experience_Images {
    // @include for-mobile-landscape {
    //   ul.for-desktop {
    //     display: none !important;
    //   }
    //   margin-bottom: 8%;
    // }
    // @include for-mobile {
    //   ul.for-desktop {
    //     display: none !important;
    //   }
    //   margin-bottom: 8%;
    // }
 
  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: minmax(0, auto);
    grid-gap: 5px;

    @include for-mobile{
      grid-template-columns: unset;
      grid-auto-rows: unset;
      grid-gap: unset;
      display: -webkit-box;
      flex-direction: row;
      overflow-x: scroll;
      flex-wrap: nowrap;
    }
    & .Experience_Image {
      background-size: cover;
      background-position: center;
    }
    
    & .rectangle {
      padding-bottom: 70%;
    }

    & li {
      display: flex;

      @include for-tablet {
        min-height: 150px;
        min-width: 150px;
      }
    }
    
   
    & li:nth-child(1) {
      grid-column: 1 / 3;
      grid-row: 1 / 3;
      @include for-mobile{
      grid-row:unset;
      grid-column: unset;
      position: relative;
      background-repeat: no-repeat;
      width: 100%;
      display: flex;
      flex-direction: row;
      }
    }
    & li:nth-child(2) {
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      @include for-mobile{
      grid-row:unset;
      grid-column: unset;
      position: relative;
      background-repeat: no-repeat;
      width: 100%;
      display: flex;
      flex-direction: row;
      }
    }
    & li:nth-child(3) {
      grid-column: 4 / 5;
      grid-row: 1 / 2;
      @include for-mobile{
      grid-row:unset;
      grid-column: unset;
      position: relative;
      background-repeat: no-repeat;
      width: 100%;
      display: flex;
      flex-direction: row;
      }
    }
    & li:nth-child(4) {
      grid-column: 3 / 4;
      grid-row: 2 / 3;
      @include for-mobile{
      grid-row:unset;
      grid-column: unset;
      position: relative;
      background-repeat: no-repeat;
      width: 100%;
      display: flex;
      flex-direction: row;
      }
    }
    & li:nth-child(5) {
      grid-column: 4 / 5;
      grid-row: 2 / 3;
      @include for-mobile{
        grid-row:unset;
        grid-column: unset;
        position: relative;
        background-repeat: no-repeat;
        width: 100%;
        display: flex;
        flex-direction: row;
      }
      & .rectangle {
        border-radius: 5px;
        position: relative;
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          margin-top: calc(50% - 0.5rem);
          width: 100%;
          text-align: center;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  
  .CarouselImageItem {
    width: auto;
    height: 224px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
  }

  .CarouselImage {
    width: -webkit-fill-available;
  } 
  .ETCarousel .VueCarousel {
      display: unset;
  }
  .VueCarousel{
    flex-direction: unset;
    position: unset;
  }
  .VueCarousel-pagination .VueCarousel-dot-container{
    margin-top:unset !important;
    margin-bottom:15px !important;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
  }
}

  h2 {
    @include sf-font(2.6rem, $black2, 'medium');
    margin-bottom: 2.5rem;
  }
  h3 {
    @include sf-font(1.5rem, $black2, 'bold');
    color: $black2;
  }
  .Experience_Outcome {
    // border-bottom: 1px solid $secondary;
    margin-top: 3rem;
    .embed-container {
      margin-bottom: 4rem;
    }
  }

  .Experience_Assets, .resource-section {
    div.VueCarousel-inner {
      height: auto !important;
      // width: 586px;
    }
  }
  .Experience_Assets {
    margin-top: 2rem;
    margin-bottom: 20px;
    width: 100%;
    h2.header {
      @include montserrat-font(2.4rem, $black2, 'regular');
      @include for-mobile {
        text-align: center;
      }
    }
    .icon-check-circle {
      color: $secondary;
      padding-right: 0;

      @include for-mobile {
        padding-right: 8px;
      }
    }
    .asset-included {
      margin-bottom: 2rem;
      margin-top: 1rem;
      @include sf-font(2rem, $black2, 'light');

      > .head {
        display: flex;
        justify-content: space-between;
        .name {
          padding-left: 0;

          @include for-mobile {
            padding-left: 8px;
          }

          @include for-mobile-landscape {
            padding-left: 8px;
          }
        }
        > .status {
          color: $secondary;
          font-weight: 600;
          font-size: 20px;
          text-align: right;
        }
      }
      > .detail {
        margin-top: 0.5rem;
        color: $gray-light2;
      }
      > .assets {
        padding-bottom: 1.5rem;
        flex-direction: row;
        display: flex;
        flex-wrap: wrap;
      }
      > .what-to-expected-carousal {
        .VueCarousel-pagination .VueCarousel-dot-container{
          margin-top:unset !important;
          margin-bottom:15px !important;
          position: absolute;
          bottom: -60px;
          left: 0;
          right: 0;
          .VueCarousel-dot--active {
            background-color: rgba(209, 209, 209, 1) !important;
          }
        }
        
      }
    }
    .digital-asset {
      .VueCarousel {
        &-pagination--bottom-overlay {
          bottom: -50px;
        }
      }
    }

    .staff-included {
      margin-bottom: 2rem;
      @include sf-font(2rem, $black2, 'light');
      &:last-child {
        margin-bottom: 0;
      }
      .name {
        padding-left: 0;

        @include for-mobile-landscape {
          padding-left: 8px;
        }

        @include for-mobile {
          padding-left: 8px;
        }
      }
    }

    .asset-collection {
      margin-bottom: 2rem;
      @include sf-font(2rem, $black2, 'light');
      &:last-child {
        margin-bottom: 0;
      }
      .name {
        padding-left: 0;
      }
    }

    .content-block {
      .name {
        padding-left: 0;

        @include for-mobile {
          padding-left: 8px;
        }

        @include for-mobile-landscape {
          padding-left: 8px;
        }
      }
    }

    .warning-message {
      display: flex;
      padding: 10px;
      margin: 3rem 0 0 0;
      align-items: center;
      border-radius: 2px;
      background-color: $secondary;

      .icon-notification {
        font-size: 6rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-left: 1rem;
        color: $secondary;
        display: flex;
        justify-content: center;

        @include for-mobile-to-tablet {
          font-size: 5rem;
        }
      }

      .message {
        padding-left: 1rem;
        p {
          &.subject {
            margin-top: 0;
            margin-bottom: 0;
            @include sf-font(2.6rem, $black2, 'semibold');
          }

          &.descript {
            margin-top: 10px;
            margin-bottom: 0;
            @include sf-font(2rem, $black2, 'light');
          }
        }
      }
    }
  }

  .Experience_Tasks {
    @include sf-font(1.7rem, $black2, 'regular');
    padding-top: 30px;
    padding-bottom: 30px;
    .task-detail {
      padding-bottom: 20px;
      > .head {
        font-size: 20px;
        > .detail {
          margin-top: -15px;
          color: $gray-light2;
        }
      }
      .condition {
        text-align: right;
        color: $secondary;
        font-weight: 600;
        font-size: 20px;
      }
    }
  }

  .Experience_description {
    @include sf-font(1.7rem, $black2, 'regular');
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .Experience_Benefits {
    .scroll {
      padding: 10px 0;
      border-top: 1px solid $secondary;
      border-bottom: 1px solid $secondary;
      .right-border {
        border-right: 1px solid $secondary;
        padding: 0px 5px;
        @include only-1024 {
          padding: 0px;
          align-self: center;
          display: flex;
        }
        @include for-tablet {
          padding: 0px;
          align-self: center;
          display: flex;
        }
        @include for-mobile {
          padding: 0px;
          align-self: center;
          display: flex;
        }
      }
      .left-border {
        border-left: 1px solid $secondary;
        padding: 0px 5px;
        @include only-1024 {
          padding: 0px;
        }
        @include for-tablet {
          padding: 0px;
        }
        @include for-mobile {
          padding: 0px;
        }
      }
      .brand_logo {
        width: 100%;

        @include only-1024 {
          width: 100%;
        }

        @include for-tablet {
          width: 100%;
        }

        @include for-mobile {
          width: 100%;
        }

        @include for-mobile-landscape {
          width: 80px;
        }
      }
      .benefit-container {
        padding-left: 10px;
        padding-right: 10px;
        .benefits {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(3, minmax(10px, 1fr));
          @include for-mobile {
            width: 80%;
          }
        }
      }
      .benefit {
        white-space: pre-line;
        word-break: initial;
        &.text {
          white-space: normal;
          word-break: normal;
        }
        > i,
        p.text {
          @include montserrat-font(3rem, $black2, 'medium');
          margin-bottom: 5px;
          display: block;
          @include only-1024 {
            font-size: 2.5rem;
          }
          @include for-tablet {
            font-size: 3.5rem;
          }
          @include for-mobile {
            font-size: 3.5rem;
          }
        }
        > .name {
          width: 100%;
          margin: 0 auto;

          @include sf-font(1.4rem, $black2, 'light');
          margin-bottom: 0;
          white-space: pre-line;
        }
      }
      > * {
        display: flex;
        flex-flow: row wrap;
        text-align: center;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .experience-categories {
    margin-top: 5rem;
  }

  .Staff_Included {
    width: 100%;
    border-top: 1px solid $gray-light2;
    border-bottom: 1px solid $gray-light2;
    padding: 30px 0;

    @include for-mobile-to-tablet {
      width: 100%;
      border-bottom: 0;
    }

    .title {
      color: $gray-light2;
      h2 {
        margin-bottom: 10px;
        @include sf-font(2.6rem, $black2, 'medium');
      }
      p {
        margin-bottom: 28px;
        line-height: 20px;
        @include sf-font(2rem, $gray-dark, 'medium');
      }
    }
    .content {
      .staff .checked,
      .staff h3,
      .promote .checked,
      .promote h3,
      .enough-stock .checked,
      .enough-stock h3,
      .enough-stock div p,
      .help-us .checked,
      .help-us h3,
      .help-us div p {
        display: inline;
      }
      .staff,
      .promote,
      .enough-stock,
      .help-us {
        display: flex;
      }
      .staff h3,
      .promote h3,
      .enough-stock h3,
      .help-us h3 {
        @include sf-font(2rem, $black2, 'bold');
      }
      p {
        @include sf-font(2rem, $black2, 'light');
        margin-top: 7px;
        margin-bottom: 25px;
        font-weight: 300;
      }
      .checked {
        background-image: url('/public/icon/svg/checked.svg');
        background-size: contain;
        background-position: center;
        height: 14px;
        width: 30px;
      }
      .desc {
        position: relative;
        margin-left: 8%;
      }
    }
  }

  .pickers {
    // border: 1px solid #70707026;
    // box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    background:#ffff;

    .pickers-date-show {
      .time-select-range {
        display: flex;
        justify-content: space-between;
        // padding: 0 2rem 1rem 2rem;
        gap: 24px;
        @include for-mobile {
          display: block;
        }
        .el-date-editor {
          width: 100% !important;
          @include for-mobile {
            width: 100% !important;
            margin-bottom: 20px;
          }
        }
      }
      .time-select-single {
        display: flex;
        justify-content: center;
        // padding: 0 2rem 1rem 2rem;
        .el-date-editor {
          width: 100% !important;
        }
      }
    }

    .pickers_how-to {
      padding: 3.5rem 3.5rem 0rem 3.5rem;
      border-top: 2px solid rgba(0, 0, 0, 0.1);
      p {
        @include sf-font(2rem, $gray-dark, 'bold');
      }

      span,
      label {
        @include sf-font(2.4rem, $black2, 'medium');
      }

      .pickers_how-to-text {
        @include sf-font(1.6rem, $gray-dark2, 'regular');
        padding-left: 16px;
        padding-top: 10px;

        li {
          padding-bottom: 20px;
          list-style-type: disc;
        }
      }
    }

    .pickers_header {
      // padding: 2.5rem 2rem 0 2rem;
      @include sf-font(16px, $black2, 'medium');
    }

    .pickers_sub {
      padding: 0 2rem 0 2rem;
      @include sf-font(14px, $black2, 'regular');
    }

    .pickers-button {
      padding: 0 2rem 1rem 2rem;
    }

    .time-select-wrapper {
      width: 100%;
      &.required {
        .el-select__wrapper {
          border: 1px solid $danger;
        }
      }
    }
    .date-time-picker.required .datepicker-container {
      border: 1px solid $danger;
      border-radius: 5px;
    }
  }

  .pickers-date {
    p {
      @include sf-font(1.6rem, $gray-light2, 'regular');
    }
    button {
      outline: none;
      @include general-button-o($black2, $gray-light2);
      width: 90%;
      text-align: left;
    }
    &-show {
      position: relative;
      .date-time-picker {
        min-height: 250px;
        margin-bottom: 20px;
        // padding: 15px 20px;
        @include tab-6 {
          // padding: 15px 2px;
        }
        @include tab-7 {
          // padding: 15px;
        }
      }
      .pickers-button {
        padding-bottom: 27px;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.5);
        min-height: 250px;
        .icon {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $secondary;
        }
      }
    }
  }

  .pickers-time {
    p {
      @include sf-font(1.6rem, $gray-light2, 'regular');
      &.select {
        @include sf-font(2.4rem, $black2, 'medium');
      }
    }
    button {
      outline: none;
      &[disabled='disabled'] {
        background-color: transparent !important;
      }
    }
  }

  .pickers.primary {
    background-color: transparent;
  }
  .About_brandType{
    position: relative;
    .title {
      color: $gray-light2;
      h2 {
        margin-bottom: 10px;
        @include montserrat-font(2.4rem, $black2, 'regular');
      }
      p {
        margin-bottom: 8px;
        line-height: 20px;
        @include montserrat-font(1.6rem, $black2, 'light');
      }
      h3{
        @include montserrat-font(2.4rem, $black2, 'regular');
      }
    }
    .content{
      margin-top: 3rem;
      margin-bottom: 4.5rem;
      @include for-mobile{
        margin-bottom: 5rem;
      }
      p {
        line-height: 2.8rem !important;
        margin-bottom: 8px;
        line-height: 1.15;
        @include montserrat-font(1.5rem, $black2, 'light');
      }
    }
    
  }
  .Need_To_Do {
    margin-top: 4rem;
    @include for-mobile {
      display: none;
    }
    h2 {
      @include montserrat-font(2.4rem, $black2, 'medium');
      margin-bottom: 1rem;
      @include for-mobile {
        text-align: center;
      }
    }
    .subTitle {
      @include sf-font(1.8rem, $black2, 'regular');
      @include for-mobile {
        text-align: center;
      }
    }
    .content {
      background-color: #fafafa;
      padding: 1.8rem 3.6rem;
      @include only-1024 {
        padding: 2rem;
      }
      @include for-mobile {
        padding: 2.5rem;
      }
    }
    @include for-mobile {
      margin-bottom: 3rem;
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .bg_brandTypeImage {
    position: relative;
  }
  .Hero_serve {
    h2 {
      @include montserrat-font(2.4rem, $black2, 'regular');
      @include for-mobile {
        text-align: center;
      }
    }
    .serve {
      @include for-mobile {
        .show-t{
          display: none;
        }
        .show-d{
          display: none;
        }
      }
      @include for-tablet{
        .show-m{
          display: none;
        }
        .show-d{
          display: none;
        }
      }
      @include for-desktop{
        .show-m{
          display: none;
        }
        .show-t{
          display: none;
        }
      }
      margin-top: 1rem;
      padding-bottom: 1.5rem;
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      .shadow_serve {
        border: 1px solid $border4;
        border-radius: 4px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
        background: #fff;
      }

      .serve_images {
        padding-top: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .serve_leadserve {
        max-height: 100px;
        height: 100px;
        text-align: center;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  @include for-mobile {
    .pickers {
      background: rgba(255, 255, 255, 0.8);
    }

    .bg_brandTypeImage-m {
      background-position-x: 30px;
      background-position-y: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      min-height: 210px;
      margin-bottom: 20px;
    }

    .bg_brandTypeImage-d {
      display: none;
    }

    .bg_brandTypeImage-t {
      display: none;
    }

    .About_brandType {
      margin-left: -15px;
      margin-right: -15px;
      > .bg_about_brandType {
        > * {
          padding-top: 30px;
        }
        .content {
          margin-right: 80px;
        }
      }
    }
  }
  @include for-mobile-landscape {
    .pickers {
      background: rgba(255, 255, 255, 0.8);
    }

    .bg_brandTypeImage-land-m{
      background-position-x: right;
      background-position-y: bottom;
      background-size: contain;
      background-repeat: no-repeat;
      min-height: 210px;
      width: 590px;

      @media (min-width: 480px) and (max-width: 572px) {
        width: 500px;
      }
    }

    .bg_brandTypeImage-d {
      display: none;
    }

    .bg_brandTypeImage-t {
      display: none;
    }

    .About_brandType {
      .bg_about_brandType {
        padding: 30px 26% 25px 0;
        min-height:270px;
        p{
          padding: 0px 30% 0 0px;
        }
      }
    }
  }

  @include only-1024 {}

  @include for-tablet {
    .pickers {
      background: rgba(255, 255, 255, 0.8);
    }

    .bg_brandTypeImage-t {
      background: transparent;
      img {
        position: absolute;
        right: 0;
        z-index: -99999;   
        bottom: 40px;
        width: 590px;
      }
    }

    .bg_brandTypeImage-d {
      display: none;
    }

    .bg_brandTypeImage-m {
      display: none;
    }
    .bg_brandTypeImage-land-m {
      display: none;
    }

    .About_brandType {
      .bg_about_brandType {
        padding: 40px 120px 14px 0px;
        min-height:250px;
      }
    }
  }
  
  @include for-desktop {
    .bg_brandTypeImage-d {
      img {
        width: 590px;
        position: absolute;
        z-index: -99999;
        right: 0px;
        bottom: 0px;
      }
      // @media (max-width: 1199px) {
      //   img {
      //     width: 63%;
      //     right: -5%;
      //     bottom: 862px;
      //   }
      // }
      @media (min-width: 1200px) and (max-width: 1338px) {
        img {
          right: 0px;
        }
      }
      @media (min-width: 2100px) {
        img {
          right: 25%;
        }
      }
    }

    .bg_brandTypeImage-m {
      display: none;
    }
    .bg_brandTypeImage-land-m{
      display:none;
    }
    .bg_brandTypeImage-t {
      display: none;
    }

    .About_brandType {
      .bg_about_brandType {
        padding: 25px 0;
      }
    }
  }

  @include only-1024 {
    .bg_brandTypeImage-m {
      display: none;
    }
    .bg_brandTypeImage-land-m{
      display:none;
    }
    .bg_brandTypeImage-t {
      display: block;
    }
    .bg_brandTypeImage-d {
      img {
        bottom: 750px;
      }
    }
    .About_brandType {
      .bg_about_brandType {
        padding: 73px 110px 36px 0;
      }
    }
  }
  .visible-mobile {
    display:none;
    @include for-mobile-to-tablet {
      display: block;
    }
  }
  .visible-desktop {
    display:block;
    @include for-mobile-to-tablet {
      display: none;
    }
  }
}
