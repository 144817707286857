.book-step-two{
    .warning-message {
        display: flex;
        padding: 10px;
        margin: 3rem 0 0 0;
        align-items: center;
        border-radius: 2px;
        background-color: $secondary;
  
        .icon-pound-round {
          font-size: 6rem;
          padding-top: 1rem;
          padding-bottom: 1rem;
          padding-left: 1rem;
          color: $secondary;
          display: flex;
          justify-content: center;
          
          @include lg {
            font-size:4.5rem;
          }
          @include for-mobile-to-tablet {
            font-size:5rem;
          }
        }
        .link_subject{
          @include md{
          margin-left:5%;
        }
          font-size: 2.2rem !important;
          transition: 0.3s ease-in;
          &:hover{
            opacity:0.2;
          }
        }
        .message {
          padding-left: 1rem;
          p {
            &.subject {
              margin-top: 0;
              margin-bottom: 0;
              @include sf-font(2.6rem, $black2, 'semibold');
              @include lg{
                font-size: 2.2rem !important;
              }
            }
  
            &.descript {
              margin-top: 10px;
              margin-bottom: 0;
              @include sf-font(2rem, $black2, 'light');
              @include lg{
                font-size: 1.8rem !important;
              }
            }
          }
        }
      }
}