.template-sidebar {
  display: grid;
  grid-template-columns: 100%;
  @include for-desktop {
    grid-template-columns: 192px auto;
  }
  @include for-normal-desktop {
    grid-template-columns: 240px auto;
  }
  .SidebarNav {
    > * {
      position: relative;
      padding-bottom: 2rem;
    
    }
  }

  .main {
    width: 100%;
    min-height: calc(100vh);
    margin-top: 12rem;
  }
  /**
  * Orders template
  */
  .order-1 { order: 1; }
  .order-2 { order: 2; }
  .order-3 { order: 3; }
  .order-4 { order: 4; }
  .order-5 { order: 5; }
  .order-6 { order: 6; }
}