@import "@/styles/variables.scss";

/* The container */
.Checkbox {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;
  @include sf-font(16px, $midGray, 'normal');
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &.disabled {
    cursor: not-allowed;
  }

  &.is-small {
    font-size: 14px;
    vertical-align: middle;
  }
  a {
    @include sf-font(16px, $midGray, 'normal');
  }
}

/* Hide the browser's default checkbox */
.Checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  border: 1px solid $border3;
  border-radius: 5px;
  &.error {
    border: 1px solid $danger;
  }
}

/* On mouse-over, add a grey background color */
.Checkbox:hover input ~ .checkmark {
  background-color: $shade2;
}

/* When the checkbox is checked, add a blue background */
.Checkbox input:checked ~ .checkmark {
  background-color: $secondary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.Checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.Checkbox .checkmark:after {
  left: 6px;
  top: 1px;
  width: 9px;
  height: 15px;
  border: solid white;
  border-width: 0 4px 4px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
