@import "@/styles/variables.scss";

.wrapper-input {
  input {
    height: 44px;
    border-radius: 5px;
    @include sf-font(16px, $text_placeholder, 'normal');
    &.error {
      border: 1px solid $danger;
    }
    &:focus {
      border: 1px solid $tertiary;
    }
  }
}
