/*-----------------------------------------------------------*/
/* UTILITY CSS
/*-----------------------------------------------------------*/
/* Display & positon
   ----------------- */
._hidden { display: none; }

._txtcenter { text-align: center; }
._center {
  margin-right: auto;
  margin-left: auto;
}
._absolute { position: absolute; }

/* Targeting children */
._inlineblocks > * { display: inline-block; }
._absolutes > * { position: absolute; }

/* Block ratio
   ----------- */
._square {
  width: 100%;
  padding-bottom: 100%;
}

/* Text & Paragraph
   ---------------- */
._bullet { list-style: initial; }
