@import "@/styles/variables.scss";

.custom-select {
  /* Hide the default arrow icon */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  /* Add your custom styles for the select box here */
}
.Select {
  @include sf-font(2rem, #3d3d3d);
  position: relative;
  padding: 18px 24px;
  border: 1px solid $shade2;

  &::before {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  select {
    width: 100%;
    @include sf-font(16px, $text_placeholder, "normal");
  }

  &.-hide-arrow::before {
    display: none;
  }
}
