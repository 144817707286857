@import "@/styles/variables.scss";

.venue-profile-group {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 2rem 3rem 0rem 3.3rem;
  box-sizing: border-box;
  background-color: $primary;

  &__photo {
    display: block;
    width: 90px;
    height: 90px;
    background-color: $secondary;
    margin: 35px auto;
    border-radius: 50%;
    box-shadow: 1px 2px 15px 0px #00000036;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &__address {
    @include montserrat-font($heading2, $white, 'bold');
    text-align: center;
  }

  &__count {
    @include montserrat-font($text1, $secondary, 'regular');
    text-align: center;
    margin: 20px 0px 0px 0px;
  }
}
