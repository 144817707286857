@import "@/styles/variables.scss";

.menu {
  padding: 0;
  background-color: $primary;
  height: calc(100vh - 80px);

  .sub-menu {
    padding: 12px 24px 0;
    ul {
      padding: 12px 0 0
    }
  }
  
  li {
    
    // @include sf-font(1.6rem, #D9D9D9);
    @include montserrat-font(14px, $white);
    
    padding: 12px 10px 12px 25px;
    &.active {
      padding-left: 25px;
      a{  
        @include montserrat-font(14px,$primary, 'bold');
        // @include sf-font(1.6rem, $primary);
      }
      // border-left: 3px solid $shade6;
      background-color: $quaternary;
      &.is_venues_group {
        border-left: 3px solid $primary;
      }
    }
  }
  
  a {
    
    // @include sf-font(1.6rem, #D9D9D9);
    @include montserrat-font(14px, $white);
    line-height: 22px;
    text-decoration: none;
    position: relative;
    margin-left: 1.4rem;
    &::before {
      position: absolute;
      bottom: 0rem;
      left: -2.5rem;
    }
    &.is_venues_group {
      @include montserrat-font(14px, $white, 'regular');
    }
  }

  .active a {
    color: $shade3;
    &.is_venues_group {
      @include montserrat-font($text1, $white, 'regular');
    }
  }
}
