@import "@/styles/variables.scss";

.page-view {
  margin-top: 80px;
}
.page-title,
.result {
  text-align: center;
}

.Banner {
  width: 100%;

  & .cover-image {
    height: 600px;
  }
}

.Content {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 65px;
}
