@import "@/styles/variables.scss";

.Page-Loader.-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &.background {
    background-color: rgba(21, 21, 21, 0.6);
    z-index: 9999;
  }
}

.Page-Loader_wrapper {
  color: $primary;
  display: flex;
  z-index: 100;

  align-items: center;
  flex-direction: column;
}
.Page-Loader_detail {
  text-align: center;
  flex-direction: row;
  width: 100%;
  margin-top: 2rem;
  color: $gray-light;
  font-weight: bold;
}
