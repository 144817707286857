@import "@/styles/variables.scss";

.template-sidebar {
  .SidebarNav {
    left: 0;
    padding-top: 80px;
    @include for-mobile-to-tablet {
      display: none;
      padding-top: 0;
    }
  }
  .main {
    @include for-mobile-to-tablet {
      margin: 12rem auto 0;
    }
  }
}
